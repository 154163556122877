import { salesPortalApi } from '@/plugins/api-client';
import { InvestorCashflow } from '@condo/domain';
import type {
    IDownloadProfitabilityCalculationSpreadsheetReq,
    IDownloadSellingCalculationsReq,
    IEstate,
    IInvestorCashflow,
    ISalesPortalEstateFilter,
} from '@condo/domain';
import type { AxiosResponse } from 'axios';

export const sendEstateContactRequest = async (estateId: number) => {
    return salesPortalApi.post(`/estates/${estateId}/contact-request`, {});
};

export const getEstate = async (estateIdOrUuid: string): Promise<IEstate> => {
    return salesPortalApi.get(`/estates/${estateIdOrUuid}`).then(response => response.data);
};

export const fetchEstateInvestorCashflow = async (estateId: number) => {
    return salesPortalApi
        .get<IInvestorCashflow | null>(`/estates/${estateId}/investor-cashflow`, {})
        .then(res => (res.data ? new InvestorCashflow(res.data) : null));
};

export const findEstates = async (params?: ISalesPortalEstateFilter): Promise<{ estates: IEstate[]; total: number }> => {
    return salesPortalApi.get('/estates', { params }).then(response => response.data);
};

export const downloadSellingCalculations = async (estateId: number, params: IDownloadSellingCalculationsReq['Body']): Promise<AxiosResponse<Blob>> => {
    return salesPortalApi.post<Blob>(`/estates/${estateId}/selling-calculations/download`, params, {
        responseType: 'blob',
        headers: { Accept: 'application/pdf' },
    });
};

export const downloadProfitabilityCalculationSpreadsheet = async (
    estateId: number,
    params: IDownloadProfitabilityCalculationSpreadsheetReq['Body'],
): Promise<AxiosResponse<Blob>> => {
    return salesPortalApi.post<Blob>(`/estates/${estateId}/profitability-calculation-spreadsheet/download`, params, {
        responseType: 'blob',
        headers: { Accept: 'application/vnd.ms-excel' },
    });
};
